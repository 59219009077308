*.app__end {
  width: 100%;
  padding: 1rem 0.5rem; // Reduced padding
  background: #f5f5f5;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-top: 1px solid #ddd;
}

.app__end-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.app__end-logo {
  display: flex;
  align-items: center;
  justify-content: center; // Center the logo within its container
  margin-bottom: 1rem;

  img {
    width: 50px;
    height: auto;
  }

  @media screen and (min-width: 768px) {
    justify-content: flex-start; // Align logo to the left on larger screens
    margin-bottom: 0;
  }
}

.app__end-check-this-out {
  text-align: center;
  margin-bottom: 1rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
}

.app__end-title {
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.app__end-links {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin: 0.3rem 0;

    a {
      color: #333;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 0.8rem;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: #7338A0;
      }
    }
  }
}

.app__end-socials {
  text-align: center;
  margin-bottom: 1rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }

  .socials-title {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
  }

  a {
    display: block; // Make each icon appear below the other
    margin: 0.5rem 0; // Add spacing between the icons
    font-size: 1.2rem;
    color: #333;
    transition: color 0.3s ease-in-out;

    &:hover {
      color: #7338A0;
    }
  }
}

.app__end-copyright {
  font-size: 0.9rem; // Increased font size
  color: #333;
  text-align: center;

  @media screen and (min-width: 768px) {
    text-align: right; // Keep it on the right for larger screens
  }
}
